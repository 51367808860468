import { Controller } from '@hotwired/stimulus';
import speechQueueService from './helpers/speech_queue_service';

export default class extends Controller {
  static targets = ['message'];

  static values = {
    inputId: String,
    playOnPageLoad: Boolean,
  };

  connect() {
    const speechInstruction = this.messageTarget.dataset
      .speechSynthSpeechInstruction;
    this.message = this.getMessage(speechInstruction);

    if (this.message === '') {
      return;
    }

    if (this.playOnPageLoadValue) {
      // Delay for 1 sec to avoid this error: An unexpected error occurred during speech synthesis.
      setTimeout(() => {
        this.enqueueSpeech();
      }, 1000);
    }
  }

  getMessage(speechInstruction) {
    return speechInstruction && speechInstruction.trim() !== ''
      ? speechInstruction
      : this.messageTarget.innerText;
  }

  clickElement(evt) {
    evt.preventDefault();
    this.enqueueSpeech();
  }

  enqueueSpeech() {
    speechQueueService.enqueue(this.message, this.messageTarget);
  }

  focusOnInput() {
    $(`#${this.inputIdValue}`).focus();
  }
}
