import Logger from './logger';

const strip = (transcript) => {
  return transcript.replace(/\s+/g, ' ').trim();
};

const logAlternativeResults = (results) => {
  for (let i = 0; i < results.length; i += 1) {
    const transcript = strip(results[i].transcript);

    if (transcript === '') {
      return;
    }

    Logger.log(`[ALTERNATIVE] ${i}: "${transcript}"`);
  }
};

const calculatedTranscript = (event) => {
  for (let i = event.resultIndex; i < event.results.length; i += 1) {
    logAlternativeResults(event.results[i]);

    return strip(event.results[i][0].transcript);
  }

  return '';
};

export default {
  calculatedTranscript,
};
