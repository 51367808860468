const buildSettings = (settingsStr) => {
  const defaultSettings = {
    autoclose: true,
    startDate: '0d',
  };

  if (!settingsStr) return defaultSettings;

  const customSettings = Object.fromEntries(
    settingsStr.split(',').map((pair) => {
      const [key, value] = pair
        .trim()
        .split(':')
        .map((str) => str.trim());
      return [key, value];
    }),
  );

  return { ...defaultSettings, ...customSettings };
};

export default {
  buildSettings,
};
