import speechHelper from './speech_helper';
import Logger from './logger';

class SpeechQueueService {
  constructor() {
    if (SpeechQueueService.instance) {
      return SpeechQueueService.instance;
    }
    this.queue = [];
    SpeechQueueService.instance = this;
  }

  messageAlreadyOnQueue(transcript) {
    const messages = this.queue.map(({ message }) => message.toLowerCase());

    return messages.includes(transcript.toLowerCase());
  }

  enqueue(message, messageTarget = null) {
    if (this.messageAlreadyOnQueue(message)) {
      Logger.log(`[REJECTING DUPLICATE ON QUEUE] "${message}"`);
      return;
    }

    this.queue.push({ message, messageTarget });

    this.processQueue();
  }

  async processQueue() {
    if (this.queue.length === 0) {
      return;
    }

    if (speechSynthesis.speaking) {
      // Currently speaking, retry in 1 sec
      setTimeout(() => {
        this.processQueue();
      }, 1000);
      return;
    }

    const { message, messageTarget } = this.queue.shift();
    await SpeechQueueService.speak(message, messageTarget).finally(() => {
      this.processQueue();
    });
  }

  static appendIcon(messageTarget) {
    if (!messageTarget) {
      return;
    }

    const speakIcon = document.createElement('i');
    speakIcon.className = 'fa fa-volume-up';
    messageTarget.appendChild(speakIcon);
  }

  static removeIcon(messageTarget) {
    if (!messageTarget) {
      return;
    }

    const speakIcon = messageTarget.querySelector('.fa-volume-up');
    if (!speakIcon) return;

    setTimeout(() => {
      // Remove speaking indicator icon in 2 sec
      const existingSpeakIcon = messageTarget.querySelector('.fa-volume-up');
      if (existingSpeakIcon) {
        messageTarget.removeChild(existingSpeakIcon);
      }
    }, 2000);
  }

  static seperateCharactersIfInSquareBrackets(message) {
    return message.replace(/\[(.+?)\]/g, (match, content) => {
      return content.split('').join(' ');
    });
  }

  static async speakPromise(utterance) {
    return new Promise((resolve) => {
      utterance.addEventListener('end', () => {
        Logger.log('[UTTERANCE ENDED]');
        speechHelper.resumeSpeechRecognition('utterance.onend');
        resolve();
      });
      utterance.addEventListener('error', (event) => {
        alert(`Speech synthesis failed: ${event.error}`);
        speechHelper.resumeSpeechRecognition('utterance.onerror');
        resolve();
      });
    });
  }

  static async speak(message, messageTarget) {
    SpeechQueueService.appendIcon(messageTarget);

    const normalisedMessage = SpeechQueueService.seperateCharactersIfInSquareBrackets(
      message,
    );

    const utterance = new SpeechSynthesisUtterance(normalisedMessage);

    Logger.log(`[FEEDBACK] "${normalisedMessage}"`);

    try {
      speechSynthesis.cancel();
      speechSynthesis.speak(utterance);
    } catch (error) {
      alert('An unexpected error occurred during speech synthesis:', error);
      SpeechQueueService.removeIcon(messageTarget);
      throw error;
    } finally {
      SpeechQueueService.removeIcon(messageTarget);
    }

    return this.speakPromise(utterance);
  }
}

const speechQueueService = new SpeechQueueService();
export default speechQueueService;
