import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['output'];

  static values = {
    provider: String,
    video: String,
  };

  connect() {
    $('#videoModal')
      .on('shown.bs.modal', this.autoPlay.bind(this))
      .on('hidden.bs.modal', this.pauseVideo.bind(this));
  }

  loadVideo() {
    this.outputTarget.innerHTML = this.constructor.embedVideo(
      this.providerValue,
      this.videoValue,
    );

    if (this.providerValue === 'vidyard') {
      this.constructor.reloadPlayVidyardScript();
    }
  }

  autoPlay() {
    // No handling for Youtube because it autoplays
    if (this.providerValue === 'vidyard') {
      setTimeout(() => {
        Vidyard.players()[this.videoValue].play();
      }, 500);
    }
  }

  static embedVideo(provider, video) {
    if (provider === 'youtube') {
      return this.embedYoutube(video);
    }
    if (provider === 'vidyard') {
      return this.embedVidyard(video);
    }
    return false;
  }

  static embedVidyard(video) {
    return `<img style='width: 100%; margin: auto; display: block;' class='vidyard-player-embed' src='https://play.vidyard.com/${video}.jpg' data-uuid='${video}' data-v='4' data-type='inline' />`;
  }

  static embedYoutube(video) {
    return `<iframe class='player_iframe' width='100%' height='650px' src='https://www.youtube.com/embed/${video}?enablejsapi=1&autoplay=1' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' allowfullscreen></iframe>`;
  }

  pauseVideo() {
    if (this.providerValue === 'youtube') {
      this.constructor.pauseYoutube();
    } else if (this.providerValue === 'vidyard') {
      Vidyard.players()[this.videoValue].pause();
    }
  }

  static pauseYoutube() {
    const selection = document.getElementsByTagName('iframe');
    const iframes = Array.prototype.slice.call(selection);

    iframes.forEach(function(iframe) {
      iframe.contentWindow.postMessage(
        `{"event":"command","func":"pauseVideo","args":""}`,
        '*',
      );
    });
  }

  static reloadPlayVidyardScript() {
    const vidyardJS = document.getElementById('play-vidyard-com');
    if (vidyardJS) {
      vidyardJS.remove();
    }
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.src = 'https://play.vidyard.com/embed/v4.js';
    script.id = 'play-vidyard-com';
    head.appendChild(script);
  }
}
