import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['clearAndFocus', 'focus'];

  connect() {
    if (this.hasClearAndFocusTarget) {
      this.clearAndFocusTarget.value = '';
      this.clearAndFocusTarget.focus();
    } else if (this.hasFocusTarget) {
      this.focusTarget.focus();
    }
  }
}
