document.addEventListener('trix-before-initialize', () => {
  function headerConfig(tag) {
    return {
      tagName: tag,
      terminal: true,
      breakOnReturn: true,
      group: false,
    };
  }

  const headerNum = [5, 4, 3, 2];

  for (const num of headerNum) {
    Trix.config.blockAttributes[`heading${num}`] = {
      ...headerConfig(`h${num}`),
    };
  }
});
