import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  submit() {
    this.element.submit();
  }

  submitOnEnter(event) {
    if (event.key === 'Enter') {
      this.submit();
    }
  }
}
