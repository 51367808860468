import Logger from './logger';

const resumeSpeechRecognition = (eventCaller = '') => {
  try {
    window.sharedRecognition.start();
    Logger.log(`[LISTENING RESUMED] ${eventCaller}`);
  } catch (error) {
    Logger.log(`[${eventCaller}] Error: ${error.message}`);
  }
};

export default {
  resumeSpeechRecognition,
};
