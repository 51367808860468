import Fuse from 'fuse.js';
import SpeechController from './speech_controller';
import speechQueueService from './helpers/speech_queue_service';
import integerValue from './helpers/number_helper';
import Logger from './helpers/logger';

export default class extends SpeechController {
  static values = {
    name: String,
  };

  labelInputHashes = [];

  totalRecognizedInput = 0;

  connect() {
    this.type = 'input';
    super.connect(this);
    this.generatelabelInputHashes();
    this.initFuzzyMatcher();
    this.initializeInputs();
    this.focusFirstInput();
  }

  generatelabelInputHashes() {
    this.labelInputHashes = $(
      `div[data-voice-input-name-value='${this.nameValue}']`,
    )
      .find('label + input')
      .map(function() {
        const input = $(this);
        const label = input.prev('label');
        return {
          labelText: label
            .text()
            .trim()
            .toLowerCase(),
          inputElement: input[0],
          inputId: input.attr('id'),
        };
      })
      .get();
  }

  initFuzzyMatcher() {
    const options = {
      includeScore: true,
      threshold: 0.4,
      keys: ['labelText'],
    };

    this.fuse = new Fuse(this.labelInputHashes, options);
  }

  focusFirstInput() {
    const firstInput = this.element.querySelector('input');
    if (firstInput) {
      firstInput.focus();
    }
  }

  initializeInputs() {
    this.labelInputHashes.forEach((pair) => {
      if ($(pair.inputElement).val() === '') {
        $(pair.inputElement).val('0');
      }
    });
  }

  speechHandler(transcript) {
    this.totalRecognizedInput = 0;
    this.processTranscript(transcript);

    const success = this.totalRecognizedInput > 0;
    if (!success) {
      Logger.log(`[INPUT ✗] "${transcript}"`);
      speechQueueService.enqueue('Unrecognized, please repeat');
    }
    return success;
  }

  processTranscript(transcript) {
    const instructions = transcript.split(' and ');

    for (const instruction of this.extractAcceptableInput(instructions)) {
      this.processSingleInstruction(instruction);
    }
  }

  extractAcceptableInput(instructions) {
    return instructions
      .map((instruction) => this.parseAcceptableInput(instruction))
      .filter((instruction) => instruction);
  }

  processSingleInstruction(instruction) {
    if (typeof instruction === 'number') {
      this.setValueOnActiveElement(instruction);
    } else if (typeof instruction === 'string') {
      this.setValueOnSpecifiedLabel(instruction);
    } else {
      Logger.log(
        `[DISREGARDED INSTRUCTION] not number or string: ${instruction}`,
      );
    }
  }

  isActiveElementMatching() {
    const { activeElement } = document;
    return this.labelInputHashes.some(
      (pair) => pair.inputElement === activeElement,
    );
  }

  setValueOnActiveElement(value) {
    this.totalRecognizedInput += 1;
    if (this.isActiveElementMatching()) {
      document.activeElement.value = value;
      Logger.log(`[INPUT ✓] "${document.activeElement.id}": "${value}"`);
      speechQueueService.enqueue(value.toString());
    }
  }

  setValueOnSpecifiedLabel(instruction) {
    const parts = instruction.split(' ');
    const numberPart = integerValue(parts.shift());
    const labelPart = parts.join(' ').toLowerCase();

    const result = this.fuse.search(labelPart)[0];
    if (result && numberPart !== null) {
      this.totalRecognizedInput += 1;
      const { inputElement, inputId } = result.item;
      $(inputElement).val(numberPart);
      Logger.log(`[INPUT ✓] ${inputId}: "${numberPart}"`);
      speechQueueService.enqueue(instruction);
    } else {
      Logger.log(`[INPUT ✗] ${instruction}`);
    }
  }

  parseAcceptableInput(instruction) {
    const words = instruction.toLowerCase().split(' ');
    const firstWord = words[0];
    const restOfWords = words.slice(1).join(' ');
    const number = integerValue(firstWord);
    if (typeof number === 'number') {
      const result = this.fuse.search(restOfWords)[0];
      // processSingleInstruction differentiates by type (number/string)
      if (result) {
        return `${number} ${result.item.labelText}`; // String
      }
      return number; // Number
    }

    return false; // False
  }

  disconnect() {
    super.disconnect(this);
  }
}
