import SpeechController from './speech_controller';
import Logger from './helpers/logger';

export default class extends SpeechController {
  connect() {
    this.type = 'command';
    super.connect();
  }

  speechHandler(transcript) {
    return this.constructor.matchAndClick(
      this.element,
      transcript,
      this.constructor.buttonClickedCallback,
    );
  }

  static buttonClickedCallback(buttonElement) {
    buttonElement.click();
  }

  static matchAndClick(element, transcript, callback) {
    let normalizedElementText = element.innerText.trim().toLowerCase();

    if (!normalizedElementText) {
      normalizedElementText = element.value.trim().toLowerCase();
    }

    const transcriptLowerCase = transcript.toLowerCase();

    const success =
      transcriptLowerCase.match(
        new RegExp(`(^|\\s)${normalizedElementText}(\\s|$)`),
      ) !== null;

    if (success) {
      Logger.log(
        `[COMMAND ✓] "${transcriptLowerCase}" matches "${normalizedElementText}"`,
      );
      if (callback) {
        callback(element);
      }
    } else {
      Logger.log(
        `[COMMAND ✗] "${transcriptLowerCase}" not matching "${normalizedElementText}"`,
      );
    }

    return success;
  }
}
