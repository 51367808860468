import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    apiKey: String,
    init: { type: String, default: 'true' },
  };

  static targets = ['checkbox', 'add1', 'add2', 'city', 'county', 'postcode'];

  connect() {
    if (this.initValue === 'true') {
      initAutocomplete(this.apiKeyValue);
    }

    fillAddressOnSelect(this.apiKeyValue, this.constructor.inputFieldMapping());

    if (this.initValue === 'true') {
      setInitialReadonlyState(
        this.checkboxTarget,
        this.constructor.fieldIdsToggledToReadOnly(),
        this.constructor.inputFieldMapping(),
        this.addressData(),
      );
    }

    enableReadOnlyFieldsOnCheck(
      this.checkboxTarget,
      this.constructor.fieldIdsToggledToReadOnly(),
      this.constructor.inputFieldMapping(),
      this.addressData(),
    );
  }

  static inputFieldMapping() {
    return {
      '#project_del_name': 'company',
      '#project_del_add1': 'line1',
      '#project_del_add2': 'line2',
      '#project_del_city': 'city',
      '#project_del_county': 'county',
      '#project_del_postcode': 'postcode',
    };
  }

  static fieldIdsToggledToReadOnly() {
    return [
      'project_del_add1',
      'project_del_add2',
      'project_del_city',
      'project_del_county',
      'project_del_postcode',
    ];
  }

  addressData() {
    return {
      company: $(`#project_del_name`).val(),
      line1: this.add1Target.value,
      line2: this.add2Target.value,
      city: this.cityTarget.value,
      county: this.countyTarget.value,
      postcode: this.postcodeTarget.value,
    };
  }
}
