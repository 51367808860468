import { Controller } from '@hotwired/stimulus';
import speechHelper from './helpers/speech_helper';
import voiceRec from './helpers/speech_recognition_helper';
import Logger from './helpers/logger';

export default class extends Controller {
  connect() {
    this.handlerObjectId = this.constructor.generateHandlerObjectId();
    this.constructor.initializeEventHandlerQueue();
    this.initSpeechRecognition();
    this.registerSimulateSpeechRecognitionEvent();
  }

  disconnect() {
    this.removeEventHandler();
    this.removeListener();
  }

  removeEventHandler() {
    let handlerObjectId;
    const { length } = window.eventHandlerQueue[this.type];
    for (let x = 0; x < length; x += 1) {
      [handlerObjectId] = window.eventHandlerQueue[this.type][x];

      if (this.handlerObjectId === handlerObjectId) {
        window.eventHandlerQueue[this.type].splice(x, 1);
        break;
      }
    }
  }

  removeListener() {
    this.constructor.stopSpeechRecognition();

    window.removeEventListener(
      'speechRecognitionResult',
      this.speechRecognitionResultEventListener,
    );
  }

  static generateHandlerObjectId() {
    return (Math.random() + 1).toString(36).substring(2);
  }

  static initializeEventHandlerQueue() {
    if (window.eventHandlerQueue) {
      return;
    }
    window.eventHandlerQueue = {
      command: [],
      input: [],
    };
  }

  registerSimulateSpeechRecognitionEvent() {
    window.eventHandlerQueue[this.type].push([
      this.handlerObjectId,
      (transcript) => this.speechHandler(transcript),
    ]);
  }

  handleSimulateSpeechRecognitionEvent(event) {
    Logger.log('***** LISTENER EVENT *****');

    this.constructor.utiliseVoiceForOneHandlerOnly(event.detail.transcript);
  }

  static utiliseVoiceForOneHandlerOnly(transcript) {
    Logger.log({ current: transcript, prev: window.lastVoiceRecognised });

    if (
      transcript === window.lastVoiceRecognised &&
      ['repeat instruction'].includes(transcript) === false
    ) {
      Logger.log(`[IGNORED, SAME AS LAST] "${transcript}"`);
      return;
    }

    // First, try to handle as a voice command
    for (const [_, eventHandler] of window.eventHandlerQueue.command) {
      if (eventHandler(transcript)) {
        window.lastVoiceRecognised = transcript;
        return;
      }
    }

    // If not a voice command, try to handle as voice input
    for (const [_, eventHandler] of window.eventHandlerQueue.input) {
      if (eventHandler(transcript)) {
        window.lastVoiceRecognised = transcript;
        return;
      }
    }

    Logger.log('[NOOP]');
  }

  initSpeechRecognition() {
    if (window.sharedRecognition) {
      return;
    }

    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    window.sharedRecognition = new SpeechRecognition();
    window.sharedRecognition.continuous = true;
    window.sharedRecognition.lang = 'en-GB';
    window.sharedRecognition.maxAlternatives = 3;

    window.sharedRecognition.onresult = this.handleRecognitionResult.bind(this);
    window.sharedRecognition.onerror = this.constructor.handleRecognitionError;
    window.sharedRecognition.onend = () => {
      Logger.log('[SPEECH RECOGNITION ENDED] Attempting to resume...');
      speechHelper.resumeSpeechRecognition();
    };
    window.sharedRecognition.onstart = () => {
      Logger.log('[LISTENING STARTED] sharedRecognition.onstart');
    };

    window.sharedRecognition.start();

    this.speechRecognitionResultEventListener = this.handleSimulateSpeechRecognitionEvent.bind(
      this,
    );

    window.addEventListener(
      'speechRecognitionResult',
      this.speechRecognitionResultEventListener,
    );

    window.lastVoiceRecognised = null;
  }

  handleRecognitionResult(event) {
    const transcript = voiceRec.calculatedTranscript(event);

    if (transcript === '') {
      return;
    }

    this.constructor.processRecognitionResult(transcript, 'FINAL');
  }

  static processRecognitionResult(transcript, transcriptType) {
    Logger.log('');
    Logger.log('-'.repeat(75));
    Logger.log(`[VOICE DETECTED - ${transcriptType}] "${transcript}"`);

    window.dispatchEvent(
      new CustomEvent('speechRecognitionResult', {
        detail: { transcript },
      }),
    );
  }

  static handleRecognitionError(event) {
    const disregardError = ['aborted', 'no-speech'].includes(event.error);
    if (disregardError) {
      return;
    }

    Logger.log(`[SPEECH RECOGNITION ERROR] "${event.error}"`);
  }

  static stopSpeechRecognition() {
    if (window.sharedRecognition) {
      window.sharedRecognition.stop();
      window.sharedRecognition = null;
      Logger.log(`[SPEECH RECOGNITION TERMINATED]`);
    }
  }
}
