import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form'];

  connect() {
    this.setupTurboStreamListener();
  }

  disconnect() {
    this.removeTurboStreamListener();
  }

  setupTurboStreamListener() {
    document.addEventListener(
      'turbo:before-stream-render',
      this.handleTurboStream.bind(this),
    );
  }

  removeTurboStreamListener() {
    document.removeEventListener(
      'turbo:before-stream-render',
      this.handleTurboStream.bind(this),
    );
  }

  handleTurboStream(event) {
    const streamElement = event.target;
    const streamTarget = streamElement.getAttribute('target');

    if (streamTarget === 'order_metrics') {
      this.formTarget.requestSubmit();
    }
  }
}
